// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-product-p-10-js": () => import("./../../../src/pages/product/p10.js" /* webpackChunkName: "component---src-pages-product-p-10-js" */),
  "component---src-pages-product-p-11-js": () => import("./../../../src/pages/product/p11.js" /* webpackChunkName: "component---src-pages-product-p-11-js" */),
  "component---src-pages-product-p-14-js": () => import("./../../../src/pages/product/p14.js" /* webpackChunkName: "component---src-pages-product-p-14-js" */),
  "component---src-pages-product-p-16-js": () => import("./../../../src/pages/product/p16.js" /* webpackChunkName: "component---src-pages-product-p-16-js" */),
  "component---src-pages-product-p-2-js": () => import("./../../../src/pages/product/p2.js" /* webpackChunkName: "component---src-pages-product-p-2-js" */),
  "component---src-pages-product-p-3-js": () => import("./../../../src/pages/product/p3.js" /* webpackChunkName: "component---src-pages-product-p-3-js" */),
  "component---src-pages-product-p-4-js": () => import("./../../../src/pages/product/p4.js" /* webpackChunkName: "component---src-pages-product-p-4-js" */),
  "component---src-pages-product-p-5-js": () => import("./../../../src/pages/product/p5.js" /* webpackChunkName: "component---src-pages-product-p-5-js" */),
  "component---src-pages-product-p-6-js": () => import("./../../../src/pages/product/p6.js" /* webpackChunkName: "component---src-pages-product-p-6-js" */),
  "component---src-pages-product-p-8-js": () => import("./../../../src/pages/product/p8.js" /* webpackChunkName: "component---src-pages-product-p-8-js" */),
  "component---src-pages-product-p-9-js": () => import("./../../../src/pages/product/p9.js" /* webpackChunkName: "component---src-pages-product-p-9-js" */),
  "component---src-pages-product-pfirst-js": () => import("./../../../src/pages/product/pfirst.js" /* webpackChunkName: "component---src-pages-product-pfirst-js" */)
}

